import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, Stack, Typography, Chip, Badge } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CountryFlag from "components/shared/CountryFlag";
import { FormattedDateTime, FromNow } from "components/shared/FormattedDate";
import Platform from "components/shared/Platform";
import TradeServices from "services/TradeService";
import FlexBox from "components/flexbox/FlexBox";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { ProfileImageWithAccount } from "components/shared/PartnerDetails";
import DateTime from "components/shared/DateTime";
import CreatedBy from "components/shared/CreatedBy";
import BadgeMui from "components/shared/BadgeMui";
import { Paragraph, Small } from "components/Typography";
import InfiniteScroll from "react-infinite-scroll-component";
import KYCService from "services/KYCService";
import { UserContext } from "page-sections/user-list/ViewUser";

export const LoadingMore = () => (
  <div className="d-flex text-center mt-5 justify-content-center align-items-center overflow-hidden">
    <i className={`fa fa-circle-notch fa-spin text-primary fa-lg`}></i>
    <span className="ms-2">Loading...</span>
  </div>
);

function AllKyc({ trade }) {
  const { userdata } = useContext(UserContext);

  const [kycs, setkycs] = useState(null);
  const [count, setCount] = useState(null);

  const getAllKyc = async (buyer_id, user_id, skip, limit) => {
    const resp = await KYCService.getAllKyc(buyer_id, user_id, skip, limit);
    if (resp.status) {
      setkycs(skip > 0 ? kycs?.concat(resp.data) : resp.data);
      setCount(resp.count);
    }
  };

  const getMoreKYC = () => {
    getAllKyc(trade.buyer_id, userdata._id, kycs.length, 20);
  };

  useEffect(() => {
    getAllKyc(trade.buyer_id, userdata._id, 0, 20);
  }, []);

  return (
    <>
      <FlexBox ml={2} my={3} sx={{ alignItems: "center" }}>
        {trade?.buyer && (
          <ProfileImageWithAccount
            account={trade.buyer}
            pfSize={30}
            buyer
            platform={trade.account.platform}
            platformSize={15}
          />
        )}
        <Box ml={0.8}>
          {/* <FlexBox sx={{ alignItems: "center" }}> */}
          <Paragraph mr={0.5} fontSize={15}>
            {trade.buyer.username} &apos;s KYC History
          </Paragraph>
          {/* </FlexBox> */}
        </Box>
      </FlexBox>
      <Divider />

      {kycs && (
        <div className="kyc-sidebar-wrapper">
          <Kycs kycs={kycs} count={count} onNext={getMoreKYC} trade={trade} />
        </div>
      )}
    </>
  );
}

export default AllKyc;

const Kycs = ({ kycs, count, onNext, trade }) => {
  console.log("kycs", kycs);
  return (
    <InfiniteScroll
      dataLength={kycs.length}
      next={onNext}
      hasMore={kycs.length < count}
      loader={<LoadingMore />}
      scrollableTarget={"kyc-sidebar-wrapper"}
      scrollThreshold={0.9}
    >
      {kycs &&
        kycs.map((item, index) => {
          return (
            <>
              {item.trade?.otpVerifiedAt && (
                <Box
                  p={2}
                  pb={0}
                  className="prev-kyc-tile"
                  key={`kyc_${index}`}
                >
                  <Small fontSize={13} fontWeight={400}>
                    {getBuyerFullName(trade)}
                  </Small>

                  <FlexBox
                    mb={0.5}
                    p={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor: "aliceblue",
                      borderRadius: "10px",
                    }}
                  >
                    <FlexBox sx={{ alignItems: "center" }}>
                      <CountryFlag
                        code={item.mobileLookup.lookup_info.countryCode}
                        width="18px"
                        showToolTip={false}
                      />
                      <Small color="black" fontSize={13} fontWeight={600}>
                        {item.mobileLookup.mobile_number}
                      </Small>
                    </FlexBox>

                    <small className="fw-semibold">
                      {item?.trade?.otp_verified ? (
                        <BadgeMui color="success.main">Verified</BadgeMui>
                      ) : (
                        <BadgeMui color="error.main">Not Verified</BadgeMui>
                      )}
                    </small>
                  </FlexBox>
                  <FlexBox
                    sx={{ justifyContent: "space-between" }}
                    className="d-flex flex-stack"
                  >
                    <div className="fs-7">
                      <Paragraph color="" fontSize={12} fontWeight={500}>
                        {item.mobileLookup.lookup_info.carrier.name}
                      </Paragraph>
                    </div>
                    <div>
                      <BadgeMui color="primary.main">
                        {item.mobileLookup.lookup_info.carrier.type}
                      </BadgeMui>
                    </div>
                  </FlexBox>
                  <Box mb={2} className="d-flex flex-stack mt-1">
                    <Small
                      sx={{ display: "block" }}
                      fontSize={12}
                      fontWeight={500}
                    >
                      {item?.trade?.otpVerifiedAt && (
                        <DateTime date={item?.trade?.otpVerifiedAt} />
                      )}
                    </Small>
                    <Small
                      sx={{
                        display: "block",
                        width: 100,
                        fontSize: 10,
                        borderRadius: "4px",
                        textAlign: "center",
                        // padding: ".2rem 1rem",
                        backgroundColor: "lightgrey",
                        color: "black",
                        margin: ".3rem 0rem",
                      }}
                    >
                      <FromNow date={item.trade?.otpVerifiedAt} />
                    </Small>
                    <Small fontSize={12}>
                      {item?.trade?.KYC_verifiedBy && (
                        <CreatedBy
                          user={item?.trade?.KYC_verifiedBy}
                          verified
                        />
                      )}
                    </Small>
                  </Box>
                  <Divider />
                </Box>
              )}
            </>
          );
        })}
    </InfiniteScroll>
  );
};

export function getBuyerFullName(trade) {
  if (
    trade.trade.offer_type === "sell" &&
    trade.trade?.buyer_full_name &&
    trade.trade.buyer_full_name.first_name
  ) {
    return `${trade.trade.buyer_full_name.first_name} ${trade.trade.buyer_full_name?.last_name}`;
  } else if (
    trade.trade.offer_type === "buy" &&
    trade.trade?.seller_full_name &&
    trade.trade.seller_full_name.first_name
  ) {
    return `${trade.trade.seller_full_name.first_name} ${trade.trade.seller_full_name?.last_name}`;
  } else {
    return null;
  }
}
