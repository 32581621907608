import { React, useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  ListItem,
  Popover,
  Stack,
  styled,
  Tab,
  Table,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import AvatarGroup from "@mui/material/AvatarGroup";
import TableBody from "@mui/material/TableBody";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableCell from "@mui/material/TableCell";
import { alpha, Box, height } from "@mui/system";
import AppAvatar from "components/avatars/AppAvatar";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import Scrollbar from "components/ScrollBar";
import {
  H1,
  H4,
  H5,
  H6,
  Paragraph,
  Small,
  Span,
  Tiny,
} from "components/Typography";
import InvertColors from "icons/InvertColors";
import KeyframeBezierIn from "icons/KeyframeBezierIn";
import PaletteOutlined from "icons/PaletteOutlined";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";
import { FromNow } from "components/shared/FormattedDate";
import EditButton from "../EditButton";

import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import Badge, { getRoleColor } from "utils/Badge";
import moment from "moment-timezone";
import LastActive from "components/shared/LastActive";
import UserSymbol from "components/shared/UserSymbol";
import AddEmployeeModal from "page-sections/data-table/dataTableV2/AddEmployeeModal";
import NoResultFound from "components/shared/NoResultFound";
import FlexBetween from "components/flexbox/FlexBetween";
import PasswordButton from "../PasswordButton";
import PasswordModal from "page-sections/data-table/PasswordModal";
import DeleteButton from "../DeleteButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import UserService from "services/UserService";
import FlexBox from "components/flexbox/FlexBox";
import GoogleIcon from "icons/GoogleIcon";
import Moment from "react-moment";
import TeamMemberRow from "./TeamMemberRow";
import OnlineStatus from "components/shared/OnlineStatus";
import MfaCheck from "components/shared/MfaCheck";
import { useNavigate } from "react-router-dom";
import ATooltip from "components/shared/ATooltip";
import LockButton from "./LockButton";
import SignInMethod from "components/shared/SignInMethod";
import Platform from "components/shared/Platform";
import DeleteDialog from "components/shared/DeleteDialog";
import CreatedBy from "components/shared/CreatedBy";
import VendorInviteService from "services/VendorInviteService";
import CountryFlagByPhone from "components/CountryFlagByPhone";
import { formatMobileNumber } from "utils/utils";
import Countdown from "react-countdown";
import BanButton, { BanUser } from "./BanButton";
import ProfilePicButton from "./profileButton";
import { UserContextData } from "App";

// styled components

const GooglePicture = styled("img")`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

const CustomTab = styled(Tab)`
  padding: 0px;
`;

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));
const StyledAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
  justifyContent: "flex-end",
  "& .MuiAvatarGroup-avatar": {
    width: 25,
    height: 25,
    fontSize: 12,
  },
}));
const IconWrapper = styled(FlexRowAlign)(({ color }) => ({
  width: 35,
  height: 30,
  borderRadius: "4px",
  backgroundColor: alpha(color, 0.2),
}));
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "start",
  },
}));

const countDownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (!completed) {
    return (
      <>
        <span className="fs-7">
          {days > 0 ? `${days}d` : ""} {hours > 0 ? `${hours}h` : ""}{" "}
          {minutes > 0 ? `${minutes}m` : ""} {seconds}s
        </span>{" "}
        <span>until expiration</span>
      </>
    );
  }
  return <></>;
};

const Teams = ({
  _handleChange,
  handleSelectedClick,
  active,
  anotherMember,
}) => {
  console.log("active", active);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [openRevokeInvitation, setOpenRevokeInvitation] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  // const { getUserMasterList } = useContext(UserContextData);

  const [open, setOpen] = useState(false);

  // Get data from usercontext api
  const {
    value,
    userdata,
    getUser,
    team,
    getUserTeamMember,
    invitationHistory,
    getUserPendingHistory,
  } = useContext(UserContext);

  const [userToDelete, setUserToDelete] = useState(null);
  const [userAction, setUserAction] = useState(null);

  const [user, setUser] = useState(null);

  const theme = useTheme();

  const changePassword = (user) => {
    setOpenPasswordModal(true);
    setUser(user);
  };

  const editUser = (user) => {
    setOpenEditModal(true);
    setUser(user);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmDeleteUser = (item, action) => {
    setUserAction(action);
    setOpen(true);
    setUserToDelete(item);
  };

  const handleCancelConfirm = () => {
    setOpen(false);
    setUserToDelete(null);
  };

  console.log("userAction", userAction);
  const handleDeleteUser = async () => {
    if (value != 1) {
      let resp;
      switch (userAction) {
        case "ban":
        case "unban":
          resp = await UserService.BanUser(userToDelete._id);
          break;
        case "block":
        case "unblock":
          resp = await UserService.BlockUser(userToDelete._id);
          break;
        case "pic":
          resp = await UserService.imageRemove(userToDelete._id);
          break;
        case "delete":
          resp = await UserService.DeleteUser(userToDelete._id);
          break;
      }

      if (resp.status) {
        if (userAction === "delete" && userToDelete.role == "admin") {
          navigate("/dashboard/users");
        }

        getUserTeamMember(userdata._id, 0);
        // getUserMasterList();
        // window.location.reload();
      }
    } else {
      // delete pending history entries
      await VendorInviteService.deleteInvitaitonHistory(userToDelete._id); //invitaionLogId
      getUserPendingHistory(userdata._id);
    }
    setOpen(false);
  };

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const _handleClose = () => {
    setAnchorEl(null);
  };

  const isActive = (date) => {
    const fiveMinutesAgo = moment().subtract(10, "minutes");
    const is_online = moment(date).isAfter(fiveMinutesAgo);
    return is_online;
  };
  const _open = Boolean(anchorEl);
  const id = _open ? "simple-popover" : undefined;

  const [sortedTeam, setSortedTeam] = useState(null);

  useEffect(() => {
    if (anotherMember) {
      let team_member = team && team.find(({ _id }) => _id == anotherMember);
      console.log("runnning team_member", team_member);
      handleSelectedClick(team_member, active);
    }
  }, [anotherMember]);

  useEffect(() => {
    let sortedMember =
      team &&
      team.sort(function (a, b) {
        let today = new Date();
        let _a = a.last_active
          ? new Date(a.last_active)
          : today.setFullYear(today.getFullYear() - 1);
        let _b = b.last_active
          ? new Date(b.last_active)
          : today.setFullYear(today.getFullYear() - 1);

        return _b - _a;
      });
    setSortedTeam(sortedMember);
  }, [team]);

  // MFA Disable
  const [openMFA, setOpenMFA] = useState(false);

  const disableMFA = (user) => {
    setOpenMFA(true);
    setUser(user);
  };
  const handleMFAClose = () => {
    setOpenMFA(false);
  };
  const ConfirmDisableMFA = async () => {
    let resp = await UserService.DisableMFA(user._id);
    if (resp.status) {
      setOpenMFA(false);
      getUserTeamMember(userdata._id, 0);
    }
  };

  // const noonesAccounts = userdata.paxfullAccounts.find(
  //   ({ platform }) => platform === "noones"
  // );
  // const paxfulAccounts = userdata.paxfullAccounts.find(
  //   ({ platform }) => platform === "paxful"
  // );

  // const loginMehtod = {
  //   paxful: paxfulAccounts,
  //   noones: noonesAccounts,
  // };

  const confirmRevokeInvitation = (item) => {
    setOpenRevokeInvitation(true);
    setUserToDelete(item);
  };

  const handleCancelRevokeInvitation = () => {
    setOpenRevokeInvitation(false);
    setUserToDelete(null);
  };
  const title = (value) => {
    console.log("value", value);
    let modal = {
      title: "Delete User",
      subtext: "It will delete user permanently. Are you sure?",
      buttonText: "Delete",
    };
    switch (value) {
      case "pic":
        modal.title = "Delete Profile Picture";
        modal.subtext = "It will delete user avatar. Are you sure?";
        modal.buttonText = "Delete";
        break;
      case "ban":
        modal.title = "Temporarily Ban";
        modal.subtext = "It will ban this user. Are you sure?";
        modal.buttonText = "Ban";

        break;
      case "block":
        modal.title = "Temporarily Block";
        modal.subtext = "It will temporarily block this user. Are you sure?";
        modal.buttonText = "Block";
        break;

      case "unban":
        modal.title = "Unban User";
        modal.subtext = "It will Unban this user.Are you sure?";
        modal.buttonText = "Unban";
        break;

      case "unblock":
        modal.title = "Unblock User";
        modal.subtext = "It will unblock this user.Are you sure?";
        modal.buttonText = "Unblock";
        break;
    }
    return modal;
  };
  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <H5 mb={1}>Team</H5>
        </Stack>
        {/* tabs */}
        <StyledStack alignItems="center" direction="row">
          <Tabs
            value={value}
            onChange={_handleChange}
            indicatorColor="primary"
            textColor="primary"
            sx={{
              padding: "0px 0px 0px 0",
            }}
          >
            <CustomTab label="Active" />
            <CustomTab
              label={`Invitation History ${
                invitationHistory?.pendingInvitation > 0
                  ? `(${invitationHistory.pendingInvitation})`
                  : ""
              }`}
            />
            <CustomTab label="Deleted" />
          </Tabs>
        </StyledStack>
      </FlexBetween>

      <Scrollbar autoHide={false} sx={{ paddingRight: "10px" }}>
        {value !== 1 && (
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  borderBottom: "1px solid lightgrey",
                }}
              >
                <TableCell>
                  <Paragraph color="text.secondary" fontSize={12}>
                    NAME
                  </Paragraph>
                </TableCell>
                <TableCell align="center">
                  <Paragraph color="text.secondary" fontSize={12}>
                    TWO-STEP
                  </Paragraph>
                </TableCell>
                <TableCell>
                  <Paragraph color="text.secondary" fontSize={12}>
                    SIGN INS
                  </Paragraph>
                </TableCell>
                <TableCell align="center">
                  <Paragraph color="text.secondary" fontSize={12}>
                    ACTIONS
                  </Paragraph>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {value == 0 && userdata && (
              <>
                <TeamMemberRow
                  item={userdata}
                  _handleChange={_handleChange}
                  handleSelectedClick={handleSelectedClick}
                  confirmDeleteUser={confirmDeleteUser}
                  changePassword={changePassword}
                  editUser={editUser}
                  disableMFA={disableMFA}
                />
              </>
            )} */}
              {team && team.length == 0 && value > 0 ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <NoResultFound />
                  </TableCell>
                </TableRow>
              ) : (
                team &&
                sortedTeam &&
                sortedTeam.map((item, id) => {
                  return (
                    <>
                      <TableRow
                        key={item._id}
                        sx={{
                          marginTop: "10px !important",
                          borderRadius: "10px", // Set the border radius
                          backgroundColor:
                            active == item._id ? "grey.200" : "transparent",
                          "& > *": {
                            padding: "10px", // Apply padding to all child elements (table cells)
                          },
                          width: "100% !important",
                        }}
                      >
                        <StyledTableCell
                          onClick={() => handleSelectedClick(item, item._id)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <Stack direction="row" spacing={2} p={1}>
                            <UserSymbol
                              user={item}
                              showOnline={true}
                              isBottomZero={28}
                            />

                            <Box
                              sx={{
                                marginLeft: "10px !important",
                              }}
                            >
                              <FlexBox sx={{ alignItems: "center" }}>
                                <H6 mr={0.5}>
                                  {/* {item.first_name && item.first_name}{" "}
                              {item.last_name && item.last_name} */}
                                  {item.name}
                                </H6>
                                <Box mt={0.5}>
                                  {item.isBanned && (
                                    <Small
                                      mr={2}
                                      sx={{
                                        width: 100,
                                        fontSize: 10,
                                        color: "#fff",
                                        borderRadius: "4px",
                                        textAlign: "center",
                                        padding: ".2rem 1rem",
                                        backgroundColor: "error.main",
                                      }}
                                    >
                                      Banned
                                    </Small>
                                  )}
                                  {item.isBlocked && (
                                    <Small
                                      sx={{
                                        width: 100,
                                        fontSize: 10,
                                        color: "#fff",
                                        borderRadius: "4px",
                                        textAlign: "center",
                                        padding: ".2rem 1rem",
                                        backgroundColor: "error.main",
                                      }}
                                    >
                                      Temporarily Blocked
                                    </Small>
                                  )}
                                </Box>
                              </FlexBox>

                              <Tiny fontSize={12} fontWeight={500}>
                                {item.email == null
                                  ? item.mobile_number
                                  : item.email}
                              </Tiny>

                              <Box direction="row" spacing={2} display={"flex"}>
                                <Badge role={item.role} />
                                <Box
                                  ml={2}
                                  sx={{
                                    minWidth: "140px",
                                  }}
                                >
                                  {item.is_deleted ? (
                                    <Small
                                      sx={{
                                        width: 100,
                                        fontSize: 10,
                                        color: "#fff",
                                        borderRadius: "4px",
                                        textAlign: "center",
                                        padding: ".2rem 1rem",
                                        backgroundColor: "error.main",
                                      }}
                                    >
                                      {item.is_deleted_by_admin
                                        ? "DELETED BY ADMIN"
                                        : "DELETED BY VENDOR"}
                                    </Small>
                                  ) : (
                                    // <LastActive date={item.last_active} />
                                    <OnlineStatus
                                      member={item}
                                      is_live={false}
                                    />
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Stack>
                        </StyledTableCell>
                        <StyledTableCell>
                          <MfaCheck value={item.mfa_enabled} />
                        </StyledTableCell>

                        <StyledTableCell>
                          {/* {item?.isGoogleSignup ? (
                          <div>
                            <Small
                              aria-describedby={id}
                              variant="contained"
                              onClick={handleClick}
                              sx={{
                                width: 100,
                                fontSize: 10,
                                color: "#fff",
                                borderRadius: "4px",
                                textAlign: "center",
                                padding: ".2rem 1rem",
                                backgroundColor: "success.main",
                              }}
                            >
                              {"Google SignIn"}
                            </Small>

                            <Popover
                              id={id}
                              open={_open}
                              anchorEl={anchorEl}
                              onClose={_handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                              >
                                <GooglePicture
                                  src={item?.googleId?.picture}
                                  alt="img"
                                />
                                <Box ml={1}>
                                  <Tiny fontSize={12} fontWeight={600}>
                                    Google
                                  </Tiny>
                                  <Tiny fontSize={12} fontWeight={500}>
                                    {item.email}
                                  </Tiny>
                                </Box>
                              </Box>
                            </Popover>
                          </div>
                        ) : null} */}

                          <FlexBox sx={{ alignItems: "center" }}>
                            <Box mr={1}>
                              {item.isDiscordSignup && (
                                <ATooltip
                                  content={
                                    <SignInMethod
                                      user={item}
                                      discordId={item.discord}
                                      email={item.discord.username}
                                    />
                                  }
                                  addClass
                                >
                                  {/* <DiscordAvatar discordId={discord} width={24} /> */}
                                  <img
                                    src={
                                      "/media/logos/discord-logo-png-7617.png"
                                    }
                                    width={"30px"}
                                  />
                                </ATooltip>
                              )}
                            </Box>

                            <Box mr={1}>
                              {item.isGoogleSignup && (
                                <ATooltip
                                  content={
                                    <SignInMethod
                                      user={item}
                                      pfp={item.googleId?.picture}
                                      email={item.googleId.email}
                                    />
                                  }
                                  addClass
                                >
                                  <GoogleIcon
                                    sx={{
                                      width: "20px",
                                    }}
                                  />
                                </ATooltip>
                              )}
                            </Box>
                            <Box mr={1}>
                              {/* loginMehtod.paxful && */}
                              {item.isPaxfulSignup && (
                                <ATooltip
                                  content={
                                    <SignInMethod
                                      user={item}
                                      pfp={item.paxfulId?.picture}
                                      email={item.paxfulId?.email}
                                    />
                                  }
                                  addClass
                                >
                                  <Platform platform={"paxful"} size={28} />
                                </ATooltip>
                              )}
                            </Box>
                            <Box mr={1}>
                              {/* loginMehtod.noones &&  */}
                              {item.isNoonesSignup && (
                                <ATooltip
                                  content={
                                    <SignInMethod
                                      user={item}
                                      pfp={item.noonesId?.picture}
                                      email={item.noonesId?.email}
                                    />
                                  }
                                  addClass
                                >
                                  <Platform platform={"noones"} size={28} />
                                </ATooltip>
                              )}
                            </Box>
                          </FlexBox>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Box>
                            <FlexBox justifyContent="end">
                              <ATooltip content={"Edit User"}>
                                {value == 0 && (
                                  <EditButton onClick={() => editUser(item)} />
                                )}
                              </ATooltip>
                              <ATooltip content={"Change Password"}>
                                {value == 0 && (
                                  <PasswordButton
                                    onClick={() => changePassword(item)}
                                  />
                                )}
                              </ATooltip>
                              <ATooltip content={"Remove Avatar"}>
                                <ProfilePicButton
                                  onClick={() => confirmDeleteUser(item, "pic")}
                                />
                              </ATooltip>

                              {value == 0 && item.mfa_enabled && (
                                <ATooltip content={"Disable MFA"}>
                                  <LockButton
                                    sx={{}}
                                    onClick={() => disableMFA(item)}
                                  />
                                </ATooltip>
                              )}
                              <ATooltip
                                content={
                                  !item.isBlocked
                                    ? "Temporarily Block User"
                                    : "Unblock User"
                                }
                              >
                                <BanButton
                                  onClick={() =>
                                    confirmDeleteUser(
                                      item,
                                      !item.isBlocked ? "block" : "unblock"
                                    )
                                  }
                                />
                              </ATooltip>

                              <ATooltip
                                content={
                                  !item.isBanned ? "Ban User" : "Unban User"
                                }
                              >
                                <BanUser
                                  onClick={() =>
                                    confirmDeleteUser(
                                      item,
                                      !item.isBanned ? "ban" : "unban"
                                    )
                                  }
                                />
                              </ATooltip>
                              <ATooltip content={"Delete User"}>
                                {item.is_deleted ? null : (
                                  <DeleteButton
                                    onClick={() =>
                                      confirmDeleteUser(item, "delete")
                                    }
                                  />
                                )}
                              </ATooltip>
                            </FlexBox>
                          </Box>
                        </StyledTableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        )}
        {value === 1 && (
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  borderBottom: "1px solid lightgrey",
                }}
              >
                <TableCell
                  sx={{
                    minWidth: "150px",
                  }}
                >
                  <Paragraph color="text.secondary" fontSize={12}>
                    DATE
                  </Paragraph>
                </TableCell>
                <TableCell align="center">
                  <Paragraph color="text.secondary" fontSize={12}>
                    DETAILS
                  </Paragraph>
                </TableCell>
                <TableCell align="center">
                  <Paragraph color="text.secondary" fontSize={12}>
                    ACTIONS
                  </Paragraph>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invitationHistory && invitationHistory.data.length == 0 ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <NoResultFound />
                  </TableCell>
                </TableRow>
              ) : (
                invitationHistory &&
                invitationHistory.data &&
                invitationHistory.data.map((item, id) => {
                  return (
                    <>
                      <TableRow
                        key={id}
                        sx={{
                          marginTop: "10px !important",
                          borderRadius: "10px", // Set the border radius
                          backgroundColor:
                            active == id ? "grey.200" : "transparent",
                          "& > *": {
                            padding: "10px", // Apply padding to all child elements (table cells)
                          },
                          width: "100% !important",
                        }}
                      >
                        <StyledTableCell
                          onClick={() => handleSelectedClick(item, id)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <Paragraph fontSize={13} color="text.secondary">
                            {<FormattedDateTime date={item.createdAt} />}
                          </Paragraph>
                          <Small
                            sx={{
                              width: 100,
                              fontSize: 10,
                              color: "#fff",
                              borderRadius: "4px",
                              textAlign: "center",
                              padding: ".2rem 1rem",
                              backgroundColor: "lightgrey",
                              color: "black",
                            }}
                          >
                            <FromNow date={item.createdAt} />
                          </Small>
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            textAlign: "start",
                          }}
                        >
                          {invitationDescription(item)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {item.is_pending &&
                            !item.is_deleted &&
                            item.action !== "revoke" &&
                            item.action !== "expire" && (
                              <ATooltip content={"Revoke Invitation"}>
                                <DeleteButton
                                  onClick={() => confirmRevokeInvitation(item)}
                                />
                              </ATooltip>
                            )}
                        </StyledTableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        )}
      </Scrollbar>

      <Dialog
        open={openMFA}
        onClose={handleMFAClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{
            fontSize: "0.925rem",
          }}
        >
          <H5>MFA Disable</H5>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              fontSize: "0.925rem",
            }}
          >
            <H6>It will disable MFA to the user. Are you sure?</H6>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMFAClose}>Cancel</Button>
          <Button onClick={() => ConfirmDisableMFA()}>Remove</Button>
        </DialogActions>
      </Dialog>
      {user && (
        <PasswordModal
          open={openPasswordModal}
          onClose={() => {
            setOpenPasswordModal(false);
            setUser(null);
          }}
          data={user}
        />
      )}

      {user && (
        <AddEmployeeModal
          onSuccess={getUser}
          subuser={true}
          data={user}
          edit={true}
          open={openEditModal}
          onClose={() => {
            setOpenEditModal(false);
            setUser(null);
          }}
          getUserTeamMember={() => getUserTeamMember(userdata._id, 0)}
        />
      )}
      <DeleteDialog
        open={open}
        title={title(userAction)?.title}
        subTitle={title(userAction)?.subtext}
        handleCancel={handleCancelConfirm}
        handleConfirm={() => handleDeleteUser()}
        deletButton={title(userAction)?.buttonText}
      />

      {/* Dialog for Revoking Invitation */}
      <DeleteDialog
        open={openRevokeInvitation}
        title={"Revoke Invitation"}
        subTitle={"It will revoke this invitation. Are you sure?"}
        handleCancel={handleCancelRevokeInvitation}
        handleConfirm={() => handleDeleteUser()}
      />
    </Card>
  );
};

export default Teams;

const invitationDescription = (invitation) => {
  const {
    user,
    register_user,
    invited_via,
    email,
    link,
    inviteAcceptedAt,
    action,
    invited_by,
    expire_at,
    is_deleted,
  } = invitation;
  console.log("action", action);

  if (inviteAcceptedAt) {
    return (
      <>
        <p>
          {register_user.username ? (
            <>
              {register_user.username} (
              {invited_via === "sms" ? (
                <>
                  {email && <CountryFlagByPhone phoneNumber={email} />}{" "}
                  {email && formatMobileNumber(email)}
                </>
              ) : invited_via === "link" ? (
                link
              ) : (
                email
              )}
              )
            </>
          ) : (
            <>
              {invited_via === "sms" ? (
                <>
                  {email && <CountryFlagByPhone phoneNumber={email} />}
                  {email && formatMobileNumber(email)}
                </>
              ) : invited_via === "link" ? (
                link
              ) : (
                email
              )}
            </>
          )}{" "}
          has accepted the invitation from {invited_by?.username}
        </p>
        <div className="d-inline-block">
          {invited_by && <CreatedBy user={invited_by} is_live={false} />}
        </div>
      </>
    );
  } else if (action == "revoke") {
    return (
      <>
        <p>
          {invited_by?.first_name} {invited_by?.last_name} has revoked
          invitation {""}
          {invited_via === "link" ? "link" : "to"}{" "}
          {invited_via == "sms" && (
            <Span sx={{ mx: "5px" }}>
              <CountryFlagByPhone phoneNumber={email} />
            </Span>
          )}
          {invited_via == "sms" ? formatMobileNumber(email) : email ?? link}
        </p>
        <div className="d-inline-block">
          {invited_by && <CreatedBy user={invited_by} />}
        </div>
      </>
    );
  } else if (action == "expire") {
    return (
      <>
        <p>
          Your invitation {invited_via === "link" ? "link" : "to"}{" "}
          {email ?? link} has expired
        </p>
        <div className="d-inline-block">
          <CreatedBy user={user} />
        </div>
      </>
    );
  } else {
    let invitationText = "";
    switch (invited_via) {
      case "link":
        invitationText = (
          <>
            <p>
              {invited_by?.first_name} {invited_by?.last_name} has generated an
              invitation link {link}
            </p>
            <div className="d-inline-block">
              {invited_by && <CreatedBy user={invited_by} />}
            </div>
            {expire_at && !is_deleted && (
              <div className="d-block">
                <span className="fs-7 cursor-default">
                  <Countdown date={expire_at} renderer={countDownRenderer} />{" "}
                </span>
              </div>
            )}
          </>
        );
        break;
      case "email":
        invitationText = (
          <>
            <p>
              {invited_by?.first_name} {invited_by?.last_name} has sent an
              invitation to {email}
            </p>
            <div className="d-inline-block">
              {invited_by && <CreatedBy user={invited_by} />}
            </div>{" "}
            {expire_at && !is_deleted && (
              <div className="d-block">
                <span className="fs-7 cursor-default">
                  <Countdown date={expire_at} renderer={countDownRenderer} />{" "}
                </span>
              </div>
            )}
          </>
        );
        break;
      case "sms":
        invitationText = (
          <>
            <p>
              {/* <span> */}
              {invited_by?.first_name} {invited_by?.last_name} has sent an
              invitation via SMS to
              {/* </span> */}
              <Span sx={{ mx: "5px" }}>
                <CountryFlagByPhone phoneNumber={email} />
              </Span>
              {/* <span> */}
              {formatMobileNumber(email)}
              {/* </span> */}
            </p>

            <div className="d-inline-block">
              {invited_by && <CreatedBy user={invited_by} />}
            </div>
            {expire_at && !is_deleted && (
              <div className="d-block">
                <span className="fs-7 cursor-default">
                  <Countdown date={expire_at} renderer={countDownRenderer} />{" "}
                </span>
              </div>
            )}
          </>
        );
        break;
      default:
        break;
    }

    return invitationText;
  }
};
