import { Box, Stack } from "@mui/material";
import IconWrapper from "components/IconWrapper";
import { H5 } from "components/Typography";
import FlexBetween from "components/flexbox/FlexBetween";
import Invoice from "icons/duotone/Invoice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import buyerService from "services/buyer";

const TradePartner = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  console.log("params", id);

  const getPartner = async (id) => {
    setIsLoading(true);
    const resp = await buyerService.getPartners(id);
    setData(resp);
    setIsLoading(false);
    console.log("partners", resp);
  };

  useEffect(() => {
    getPartner(id);
  }, []);

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("Trade Partners")}</H5>
        </Stack>
      </FlexBetween>
    </Box>
  );
};

export default TradePartner;
