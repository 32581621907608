import countries from "../data/country";

function toFixed(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}

export function formatCrypto(amount, base) {
  return toFixed(amount / 10 ** base);
}
export function normalizeCrypto(
  amount,
  currency,
  ethFrom = "gwei",
  stable_base = 2
) {
  switch (currency) {
    case "BTC":
    case "SOL":
    case "TON":
      const sb = require("satoshi-bitcoin");
      if (amount % 1 != 0) {
        return amount;
      }
      // return sb.toBitcoin(amount);
      return parseFloat(sb.toBitcoin(amount)).toFixed(8);
    case "ETH":
      const convert = require("ethereum-unit-converter");
      return convert(amount, ethFrom).ether;
    case "USDT":
    case "USDC":
      if (amount > 0) {
        return formatCrypto(amount, 6).toFixed(stable_base);
      }
      return amount;
    default:
      return amount;
  }
}
export function formatCurrency(
  amount,
  currencyCode = "USD",
  currencyDisplay = "symbol"
) {
  const formatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: currencyDisplay,
  });
  return formatter.format(amount);
}
export function getCurrencyNameByCode(code) {
  const cryptoCurrencies = {
    BTC: "Bitcoin",
    ETH: "Ethereum",
    USDT: "Tether",
    USDC: "USD Coin",
    XLM: "Stellar",
    BNB: "BNB",
    LTC: "Litecoin",
    XRP: "Ripple",
    DAI: "DAI",
    TRX: "Tron",
    WBTC: "Wrapped Bitcoin",
    WETH: "Wrapped Ethereum",
    DOGE: "Dogecoin",
    SOL: "Solana",
    LINK: "Chainlink",
    ADA: "Cardano",
    UNI: "Uniswap",
  };
  return cryptoCurrencies[code];
}

export function formatCryptoDecimal(number, decimalPlaces) {
  const formattedNumber = Number(number).toFixed(decimalPlaces);
  return formattedNumber.replace(/\.?0+$/, ""); // Remove trailing zeros
}

export const getTotalBalance = (accounts, currency) => {
  //total by currency
  let balance = 0;
  accounts?.forEach((account) => {
    balance = balance + getCryptoBalance(account, currency);
  });
  return normalizeCrypto(balance, currency, "wei", 6);
};

export const getCryptoBalance = (account, currency) => {
  switch (currency) {
    case "BTC":
      const sb = require("satoshi-bitcoin");
      let balance = account.btc_balance
        ? Number(account.btc_balance.balance)
        : 0;
      if (balance % 1 != 0) {
        return balance;
      }
      return sb.toBitcoin(balance);
    case "ETH":
      return account.eth_balance ? Number(account.eth_balance.balance) : 0;
    case "USDT":
      return account.usdt_balance ? Number(account.usdt_balance.balance) : 0;
    case "USDC":
      return account.usdc_balance ? Number(account.usdc_balance.balance) : 0;
    case "SOL":
      return account.sol_balance ? Number(account.sol_balance.balance) : 0;
    case "TON":
      return account.ton_balance ? Number(account.ton_balance.balance) : 0;
  }
};

export function getCountryNameByCode(code) {
  let countryName;
  countries.forEach((country) => {
    if (country.code == code) {
      countryName = country.name;
    }
  });
  return countryName;
}
