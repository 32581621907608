import {
  Box,
  Card,
  Chip,
  Divider,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import Scrollbar from "components/ScrollBar";
import { H5, H6, Paragraph, Small, Span, Tiny } from "components/Typography";
import AppAvatar from "components/avatars/AppAvatar";
import FlexBox from "components/flexbox/FlexBox";
import CountryFlag from "components/shared/CountryFlag";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Common from "page-sections/chats/chat-v1/Common";
import FileType from "page-sections/chats/chat-v1/FileType";
import React, { useContext, useEffect, useState } from "react";
import { formatCurrency } from "utils/currencies";
import FeedbackBadge from "components/shared/FeedbackBadge";
import Badge from "@mui/material/Badge";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import FeedbackService from "services/FeedbackService";
import { ta } from "date-fns/locale";
import { TabContext, TabList } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { FormattedDateTime } from "components/shared/FormattedDate";
import { Link } from "react-router-dom";
import TradeServices from "services/TradeService";
import Platform from "components/shared/Platform";
import ATooltip from "components/shared/ATooltip";
import {
  BuyerSymbol,
  ProfileImageWithAccount,
} from "components/shared/PartnerDetails";
import { SettingsContext } from "contexts/settingsContext";
import ShowTradeDetails from "./ShowTradeDetails";
import BadgeMui from "components/shared/BadgeMui";

// import { makeStyles } from "@material-ui/core/styles";
// import Avatar from "@material-ui/core/Avatar";
// import Typography from "@material-ui/core/Typography";
// import Badge from "@material-ui/core/Badge";

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: "flex",
//     alignItems: "center",
//   },
//   avatar: {
//     marginRight: theme.spacing(1),
//   },
//   feedbackContainer: {
//     display: "flex",
//     alignItems: "center",
//     marginLeft: theme.spacing(4),
//   },
//   successBadge: {
//     marginRight: theme.spacing(2),
//   },
// }));

const TabListWrapper = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(727)]: {
    order: 3,
  },
}));

const useStyles = makeStyles((theme) => ({
  successIcon: {
    color: "green",
  },
  dangerIcon: {
    color: "red",
  },
}));

const TradeContainer = styled("div")(({ theme }) => ({
  marginTop: "10px",
  marginBottom: "10px",
}));

const PositiveFeedbackBadge = ({ count }) => (
  <Badge
    color="success"
    badgeContent={count}
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
  >
    <ThumbUpIcon color="success" />
  </Badge>
);

const NegativeFeedbackBadge = ({ count }) => (
  <Badge
    color="error"
    badgeContent={count}
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
  >
    <ThumbDownIcon color="error" />
  </Badge>
);

export const FlagAndPlatform = ({ CountryName, country, platform }) => {
  return (
    <>
      <FlexBox sx={{ alignItems: "center" }} ml={1}>
        <CountryFlag code={country} CountryName={CountryName} />
        <Box ml={1}>
          {platform && <Platform platform={platform} size={20} />}
        </Box>
      </FlexBox>
    </>
  );
};

function PartnerDetail({ trade }) {
  const { settings, saveSettings } = useContext(SettingsContext);

  return (
    <Box
      sx={
        {
          // marginBottom: "100px",
        }
      }
    >
      <Stack
        alignItems="start"
        sx={{
          padding: 2,
        }}
      >
        <FlexBox
          sx={{ alignItems: "center" }}
          className="d-flex align-items-center"
        >
          <ProfileImageWithAccount
            account={trade.buyer}
            buyer
            platform={trade.account.platform}
            platformSize={15}
            pfSize={30}
          />
          {/* <PaxfulProfilePic account={trade.buyer} size={35} buyer /> */}
          <Box
            sx={{
              marginLeft: "2px",
            }}
          >
            <FlexBox>
              {/* <a
                style={{
                  marginLeft: "3px",
                  color: "black",
                }}
                href={
                  `https://${trade.account.platform}.com/user/` +
                  trade.buyer.username
                }
                target="_blank"
                rel="noopener noreferrer"
              > */}
              <Span
                fontSize={12}
                fontWeight={600}
                my={0.5}
                color={settings.theme == "dark" ? "white" : "black"}
              >
                {trade.buyer.username} &apos;s Partner Details
              </Span>
              {/* </a> */}
              {/* <FlagAndPlatform
                CountryName={
                  trade.partner_location.detected_location.localized_name
                }
                country={trade.partner_location.detected_location.iso}
                // platform={trade.account.platform}
              /> */}
            </FlexBox>
            <div className="d-flex ms-4 align-items-center"></div>
            {/* <FeedbackBadge type="positive" value={2} /> */}
            {/* <Chip
              size="small"
              icon={<ThumbUpIcon color="success" />}
              label={trade.buyer.feedback_positive}
            />
            <Chip
              sx={{
                marginLeft: "5px",
              }}
              size="small"
              icon={<ThumbDownIcon color="error" />}
              label={trade.buyer.feedback_negative}
            /> */}
          </Box>
        </FlexBox>
      </Stack>

      <Divider />

      {/* <H5 p={2}>File Type</H5> */}
      {/* <Scrollbar
          autoHide={false}
          style={{
            maxHeight: 650,
          }}
        > */}
      <Stack p={2} spacing={3}>
        {/* <FileType /> */}
        <Details trade={trade} showBuyer={true} />
      </Stack>
      {/* </Scrollbar> */}
    </Box>
  );
}
export default PartnerDetail;

const TradingStats = ({ title, value }) => {
  return (
    <FlexBox
      sx={{
        justifyContent: "space-between",
        marginTop: "5px",
      }}
    >
      <Typography fontSize={12}>{title}</Typography>
      <Typography fontSize={12} fontWeight={500}>
        {value}
      </Typography>
    </FlexBox>
  );
};

export const Details = ({ trade, showBuyer }) => {
  const { t } = useTranslation();

  const [stats, setStats] = useState(null);
  const [feedbacks, setFeedbacks] = useState(null);
  const [tabValue, setTabValue] = useState("positive_feedback");

  const getVendorDetails = async (account_id, buyer_id) => {
    const resp = await TradeServices.getVendorDetails(account_id, buyer_id);
    if (resp.status == true) {
      setStats(resp.data);
    }
  };

  const getFeedbacks = async (username, type, platform) => {
    const feedbacks = await FeedbackService.getBuyerFeedback(
      username,
      type,
      platform
    );
    setFeedbacks(feedbacks);
  };

  useEffect(() => {
    getVendorDetails(trade?.account._id, trade?.buyer_id);
    getFeedbacks(trade.buyer.username, tabValue, trade.buyer_id);
  }, []);

  // Tab selection
  const handleTabChange = (_, value) => {
    setTabValue(value);
    getFeedbacks(trade.buyer.username, value, trade.account?.platform);
  };

  return (
    <>
      <div>
        <ShowTradeDetails trade={trade} isPast={false} />

        {showBuyer && (
          <Box mb={2} className="mb-5">
            <FlexBox
              sx={{ alignItems: "center" }}
              className="d-flex align-items-center"
            >
              <ProfileImageWithAccount
                account={trade.buyer}
                buyer
                platform={trade.account.platform}
                platformSize={18}
                pfSize={40}
              />
              {/* <PaxfulProfilePic account={trade.buyer} size={35} buyer /> */}
              <Box
                sx={{
                  marginLeft: "2px",
                }}
              >
                <FlexBox>
                  <a
                    style={{
                      marginLeft: "3px",
                      // color: "black",
                    }}
                    href={
                      `https://${trade.account.platform}.com/user/` +
                      trade.buyer.username
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Span
                      fontSize={12}
                      fontWeight={600}
                      my={0.5}
                      // color={settings.theme == "dark" ? "white" : "black"}
                    >
                      {trade.buyer.username}
                    </Span>
                  </a>
                  <FlagAndPlatform
                    CountryName={
                      trade.partner_location.detected_location.localized_name
                    }
                    country={trade.partner_location.detected_location.iso}
                    // platform={trade.account.platform}
                  />
                </FlexBox>
                <div className="d-flex ms-4 align-items-center"></div>
                {/* <FeedbackBadge type="positive" value={2} /> */}
                <Chip
                  size="small"
                  icon={<ThumbUpIcon color="success" />}
                  label={trade.buyer.feedback_positive}
                />
                <Chip
                  sx={{
                    marginLeft: "5px",
                  }}
                  size="small"
                  icon={<ThumbDownIcon color="error" />}
                  label={trade.buyer.feedback_negative}
                />
              </Box>
            </FlexBox>
          </Box>
        )}

        <Typography fontSize={13} color="text.secondary" fontWeight={600}>
          Verifications
        </Typography>
        <div>
          <FlexBox mt={1}>
            <StatusIcon status={trade.buyer.phone_verified} />
            <Typography
              sx={{
                marginLeft: "5px",
              }}
              fontSize={13}
            >
              Phone Verified
            </Typography>{" "}
          </FlexBox>
          <FlexBox className="d-flex mb-1">
            <StatusIcon status={trade.buyer.is_verified} />
            <Typography
              sx={{
                marginLeft: "5px",
              }}
              fontSize={13}
            >
              ID Verified
            </Typography>{" "}
          </FlexBox>
        </div>
      </div>

      <Box mb={2}>
        <Typography fontSize={13} color="text.secondary" fontWeight={600}>
          Last Seen
        </Typography>
        <Typography fontSize={13}>{trade.buyer.last_seen}</Typography>
      </Box>

      {trade.trade.offer_type == "sell" && (
        <div className="mt-6">
          <Typography fontSize={13} color="text.secondary" fontWeight={600}>
            Name
          </Typography>
          <Typography fontSize={13}>
            {trade.trade.buyer_full_name.first_name
              ? trade.trade.buyer_full_name.first_name
              : "None"}{" "}
            {trade.trade.buyer_full_name.last_name}
          </Typography>
        </div>
      )}

      {trade.trade.offer_type == "buy" && (
        <div className="mt-6">
          <Typography fontSize={13} color="text.secondary" fontWeight={600}>
            Name
          </Typography>
          <Typography fontSize={13}>
            {trade.trade.seller_full_name.first_name
              ? trade.trade.seller_full_name.first_name
              : "None"}{" "}
            {trade.trade.seller_full_name.last_name}
          </Typography>
        </div>
      )}

      {trade.partner_location && (
        <>
          {trade.partner_location.detected_location && (
            <Box mb={2} className="mt-6">
              <Typography fontSize={13} color="text.secondary" fontWeight={600}>
                Detected Location
              </Typography>
              <FlexBox sx={{ alignItems: "center" }}>
                <CountryFlag
                  code={trade.partner_location.detected_location.iso}
                  width={"18px"}
                  showToolTip={false}
                />
                <Typography
                  sx={{
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                  fontSize={14}
                >
                  {trade.partner_location.detected_location.name}
                </Typography>
              </FlexBox>
            </Box>
          )}
          {trade.partner_location.ip_location && (
            <div className="mt-6">
              <Typography fontSize={13} color="text.secondary" fontWeight={600}>
                IP Location
              </Typography>
              <FlexBox sx={{ alignItems: "center" }}>
                <CountryFlag
                  code={trade.partner_location.ip_location.iso}
                  width={"18px"}
                  showToolTip={false}
                />
                <Typography
                  sx={{
                    marginLeft: "5px",
                    textAlign: "center",
                  }}
                  fontSize={14}
                >
                  {trade.partner_location.ip_location.name}
                </Typography>
              </FlexBox>
            </div>
          )}
        </>
      )}

      {trade.trade.offer_type == "sell" && (
        <div className="mt-6">
          <Typography fontSize={13} color="text.secondary" fontWeight={600}>
            Carrier info
          </Typography>
          <Typography fontSize={13}>
            {trade.trade.buyer_phone_carrier
              ? trade.trade.buyer_phone_carrier
              : "Unknown"}
          </Typography>
        </div>
      )}

      {trade.trade.offer_type == "buy" && (
        <div className="mt-6">
          <Typography fontSize={13} color="text.secondary" fontWeight={600}>
            Carrier info
          </Typography>
          <Typography fontSize={13}>
            {trade.trade.seller_phone_carrier
              ? trade.trade.seller_phone_carrier
              : "Unknown"}
          </Typography>
        </div>
      )}

      <Box mt={6} className="mt-6">
        <Typography fontSize={13} color="text.secondary" fontWeight={600}>
          Trading Info
        </Typography>
        <div
          style={{
            marginTop: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={12}> Joined</Typography>
          <Typography fontSize={12} fontWeight={500}>
            {trade.buyer.joined}
          </Typography>
        </div>
        <div
          style={{
            marginTop: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={12}>Trades With You</Typography>{" "}
          <Typography fontSize={12} fontWeight={500}>
            {trade.buyer.completed_trades_with_me}
          </Typography>
        </div>
        <div
          style={{
            marginTop: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={12}>Trade Partners</Typography>
          <Typography fontSize={12} fontWeight={500}>
            {trade.buyer.total_partners}
          </Typography>
        </div>
        <div
          style={{
            marginTop: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={12}>Total Trades</Typography>
          <Typography fontSize={12} fontWeight={500}>
            {trade.buyer.total_trades}
          </Typography>
        </div>
        <div
          style={{
            marginTop: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={12}>BTC Traded</Typography>
          <Typography fontSize={12} fontWeight={500}>
            {trade.buyer.total_btc}
          </Typography>
        </div>
        <div
          style={{
            marginTop: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={12}>Trusted By</Typography>
          <Typography fontSize={12} fontWeight={500}>
            {trade.buyer.trusted_by}
          </Typography>
        </div>
        <div
          style={{
            marginTop: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={12}>Blocked By</Typography>
          <Typography fontSize={12} fontWeight={500}>
            {trade.buyer.blocked_by}
          </Typography>
        </div>
      </Box>

      {trade && (
        <TradeContainer>
          <Typography fontSize={13} color="text.secondary" fontWeight={600}>
            Venva Trading Statistics
          </Typography>
          <div
            style={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* <Typography fontSize={12}>Partner</Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <PaxfulProfilePic account={trade.buyer} buyer={true} size={15} />
              <Typography fontSize={12} fontWeight={500} color="text.secondary">
                {trade.buyer.username}
              </Typography>
            </div> */}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 8,
              justifyContent: "space-between",
            }}
          >
            <Typography fontSize={12}> Daily Trading Total</Typography>
            <Typography fontSize={12} fontWeight={500}>
              {stats ? formatCurrency(stats.daily_total) : "$0"}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 8,
              justifyContent: "space-between",
            }}
          >
            <Typography fontSize={12}> Weekly Trading Total</Typography>
            <Typography fontSize={12} fontWeight={500}>
              {" "}
              {stats ? formatCurrency(stats.weekly_total) : "$0"}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontSize={12}> Lifetime Trading Total</Typography>
            <Typography fontSize={12} fontWeight={500}>
              {stats ? formatCurrency(stats.lifetime_total) : "$0"}
            </Typography>
          </div>
        </TradeContainer>
      )}

      <Typography
        sx={{
          marginTop: "10px",
        }}
        mt={1}
        fontSize={13}
        color="text.secondary"
        fontWeight={600}
      >
        Feedbacks
      </Typography>
      <Box className="feedback" mb={2}>
        <TabContext
          sx={
            {
              // margin: "0 !important",
            }
          }
          value={tabValue}
        >
          <TabList onChange={handleTabChange}>
            <Tab
              sx={{
                marginLeft: "-7px !important",
              }}
              icon={
                <>
                  <FlexBox>
                    <Small>Positive</Small>
                    <Chip
                      sx={{
                        marginLeft: "5px",
                      }}
                      size="small"
                      icon={<ThumbUpIcon color="success" />}
                      label={trade.buyer.feedback_positive}
                    />
                  </FlexBox>
                </>
              }
              disableRipple
              // label={t("Positive")}
              value="positive_feedback"
            />
            <Tab
              icon={
                <>
                  <FlexBox>
                    <Small>Negative</Small>
                    <Chip
                      sx={{
                        marginLeft: "5px",
                      }}
                      size="small"
                      icon={<ThumbDownIcon color="error" />}
                      label={trade.buyer.feedback_negative}
                    />
                  </FlexBox>
                </>
              }
              disableRipple
              // label={t("Neagtive")}
              value="negative_feedback"
            />
          </TabList>
          <span className="mb-8">
            <Divider />
          </span>
        </TabContext>
      </Box>

      {/* Set margin 0 for TabContext */}

      {feedbacks && feedbacks.data && (
        <Feedbacks feedbacks={feedbacks.data} trade={trade} />
      )}
    </>
  );
};

const Feedbacks = ({ feedbacks, trade }) => {
  return (
    <div className="mt-6">
      <div className="d-flex align-items-center">
        {/* <Typography variant="subtitle1" color="textSecondary">
          Negative Feedbacks
        </Typography> */}
        {/* <div className="ms-2">
          <Badge
            color="default"
            badgeContent={trade.buyer.feedback_negative}
            className="badge-light-danger"
          >
            <i className="fa-solid fa-thumbs-down text-danger me-1"></i>
          </Badge>
          <Chip
            size="small"
            icon={<ThumbUpIcon color="success" />}
            label={trade.buyer.feedback_positive}
          />
          <Chip
            sx={{
              marginLeft: "5px",
            }}
            size="small"
            icon={<ThumbDownIcon color="error" />}
            label={trade.buyer.feedback_negative}
          />
        </div> */}
      </div>
      {feedbacks?.length === 0 ? (
        <Typography
          variant="body2"
          color="textSecondary"
          className="text-gray-800 partner-feedback"
        >
          No feedbacks.
        </Typography>
      ) : (
        <div className="mt-3">
          {feedbacks?.map(({ feedback, author, platform }, index) => (
            <React.Fragment key={index}>
              {index > 0 && <Separator className="my-4" />}
              <div>
                <FlexBox
                  sx={{
                    alignItems: "center",
                  }}
                  className="d-flex"
                >
                  <div>
                    {author ? (
                      // <Box
                      //   sx={{
                      //     position: "relative",
                      //   }}
                      //   className="me-3"
                      // >
                      //   <PaxfulProfilePic account={author} buyer />
                      //   <Box
                      //     sx={{
                      //       position: "absolute",
                      //       top: -5,
                      //       left: 25,
                      //     }}
                      //   >
                      //     <Platform size={15} platform={platform} />
                      //   </Box>
                      // </Box>
                      <ProfileImageWithAccount
                        account={author}
                        buyer
                        platform={platform}
                        platformSize={15}
                      />
                    ) : (
                      <BuyerSymbol username={feedback.author_username} />
                    )}
                  </div>
                  <div
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    {/* <Link
                      href={`https://${trade.account.platform}.com/user/${feedback.author_username}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-800 text-hover-primary fs-6 fw-bold"
                    >
                      {feedback.author_username}
                    </Link> */}
                    <Paragraph fontSize={14}>
                      {feedback.author_username}
                    </Paragraph>
                    <div className="fs-8 text-muted">
                      <Paragraph fontSize={12} color="text.secondary">
                        <FormattedDateTime date={feedback.created_at} />
                      </Paragraph>
                      {/* <Badge
                        color="default"
                        className="badge-light-danger ms-3"
                      >
                        <div className="fa-solid fa-thumbs-down text-danger me-1"></div>{" "}
                        <span className="fs-9">Negative</span>
                      </Badge>   */}
                    </div>
                  </div>

                  <Box
                    sx={{
                      marginInlineStart: "30px",
                    }}
                  >
                    {feedback.rating == "1" ? (
                      <BadgeMui color="success.main">
                        {" "}
                        <div className="fa-solid fa-thumbs-up text-success me-1"></div>
                        Positive
                      </BadgeMui>
                    ) : (
                      <BadgeMui color="error.main">
                        {" "}
                        <div className="fa-solid fa-thumbs-down text-danger me-1"></div>
                        Negative
                      </BadgeMui>
                    )}
                  </Box>
                </FlexBox>
                {/* <div
                  className="fw-normal text-gray-700 mt-3"
                  dangerouslySetInnerHTML={{ __html: feedback.message }}
                ></div> */}
                <Box mt={1}>
                  <Paragraph color="text.secondary" fontSize={12}>
                    {feedback.message}
                  </Paragraph>
                </Box>
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

const Separator = styled("div")({
  margin: "16px 0",
  borderTop: "1px solid #e0e0e0",
});
const StatusIcon = ({ status }) => {
  const classes = useStyles();

  return status ? (
    <span
      className={`svg-icon svg-icon-success svg-icon-3 ${classes.successIcon}`}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          opacity="0.3"
          x="2"
          y="2"
          width="20"
          height="20"
          rx="10"
          fill="currentColor"
        />
        <path
          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
          fill="currentColor"
        />
      </svg>
    </span>
  ) : (
    <span className={`${classes.dangerIcon}`}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          opacity="0.3"
          x="2"
          y="2"
          width="20"
          height="20"
          rx="10"
          fill="currentColor"
        />
        <rect
          x="7"
          y="15.3137"
          width="12"
          height="2"
          rx="1"
          transform="rotate(-45 7 15.3137)"
          fill="currentColor"
        />
        <rect
          x="8.41422"
          y="7"
          width="12"
          height="2"
          rx="1"
          transform="rotate(45 8.41422 7)"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};
