import React, { useContext } from "react";

import { FlagAndPlatform } from "./PartnerDetail";
import { Span } from "components/Typography";
import { Box, Chip } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import CryptoBalance from "components/shared/CryptoBalance";
import CryptoImg from "components/shared/CryptoImg";
import FormattedBalance from "components/shared/FormattedBalance";
import OfferType from "components/shared/OfferType";
import { formatCurrency, normalizeCrypto } from "utils/currencies";
import ATooltip from "components/shared/ATooltip";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { ChatIcon } from "components/shared/ChatIcon";
import { AttachmentIcon } from "components/shared/AttachmentIcon";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { UserContext } from "page-sections/user-list/ViewUser";
import ExternalLink from "components/ExternalLink";
import platforms from "data/platforms";
import Platform from "components/shared/Platform";

const TradeInfo = ({ title, children, isLast, className }) => {
  return (
    <Box
      mb={1}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      className={`trade-details-info d-flex flex-stack${
        !isLast ? "  mb-2" : ""
      }${className ? ` ${className}` : ""}`}
    >
      <Span fontSize={13} className="text-gray-800">
        {title}
      </Span>
      <Span fontSize={13} fontWeight={500} className="fw-semibold">
        {children}
      </Span>
    </Box>
  );
};

function ShowTradeDetails({ trade, isPast = false }) {
  const { userdata } = useContext(UserContext);
  console.log("trade.account", trade);
  return (
    <Box
      sx={{
        border: "1px solid lightgrey",
        padding: "5px",
        marginBottom: "5px",
        borderRadius: "10px",
      }}
      className="p-4 border mb-6 card card-body"
    >
      <div className="mb-3">
        {/* <TradeVendor hyperLink={true} trade={trade} /> */}
        <Box mb={2} className="mb-5">
          <FlexBox
            sx={{ alignItems: "center" }}
            className="d-flex align-items-center"
          >
            {/* <ProfileImageWithAccount
              account={trade.buyer}
              buyer
              platform={trade.account.platform}
              platformSize={18}
              pfSize={40}
            /> */}
            <Box
              sx={{
                position: "relative",
              }}
            >
              <PaxfulProfilePic
                className={"me-1"}
                size={35}
                account={trade.account}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: -7,
                  bottom: 0,
                  left: 20,
                  right: 0,
                }}
              >
                <Platform platform={trade.account.platform} size={20} />
              </Box>
            </Box>
            {/* <PaxfulProfilePic account={trade.account} size={35} /> */}
            <Box
              sx={{
                marginLeft: "2px",
              }}
            >
              <FlexBox>
                <a
                  style={{
                    marginLeft: "3px",
                    // color: "black",
                  }}
                  href={
                    `https://${trade.account.platform}.com/user/` +
                    trade.account.paxful_username
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Span
                    fontSize={12}
                    fontWeight={600}
                    my={0.5}
                    // color={settings.theme == "dark" ? "white" : "black"}
                  >
                    {trade.account.paxful_username}
                  </Span>
                </a>
                <FlagAndPlatform
                  CountryName={
                    trade.partner_location.detected_location.localized_name
                  }
                  country={trade.partner_location.detected_location.iso}
                  // platform={trade.account.platform}
                />
              </FlexBox>
              <div className="d-flex ms-4 align-items-center"></div>
              {/* <FeedbackBadge type="positive" value={2} /> */}
              <Chip
                size="small"
                icon={<ThumbUpIcon color="success" />}
                label={trade.account?.paxful_profile?.feedback_positive}
              />
              <Chip
                sx={{
                  marginLeft: "5px",
                }}
                size="small"
                icon={<ThumbDownIcon color="error" />}
                label={trade.account?.paxful_profile?.feedback_negative}
              />
            </Box>
          </FlexBox>
        </Box>
      </div>

      <TradeInfo className={"td-payment-method"} title={"Payment Method"}>
        <span>{trade.trade.payment_method_name}</span>
      </TradeInfo>

      <TradeInfo title={"Trade Amount"}>
        <FormattedBalance
          amount={trade.trade.fiat_amount_requested}
          code={trade.trade.fiat_currency_code}
          offer_type={trade.trade.offer_type}
          status={trade.status}
          user={userdata}
        />
      </TradeInfo>

      <TradeInfo title={"Crypto Amount"}>
        <CryptoImg width={15} crypto={trade.trade.crypto_currency_code} />
        <CryptoBalance
          fs={6}
          code={trade.trade.crypto_currency_code}
          amount={trade.trade.crypto_amount_total}
          offer_type={trade.trade.offer_type}
          status={trade.status}
          user={userdata}
        />
      </TradeInfo>

      <TradeInfo title={"Crypto Fiat Value"}>
        {formatCurrency(trade.fiat_value, trade.trade.fiat_currency_code)}{" "}
        {trade.trade.fiat_currency_code}
      </TradeInfo>

      <TradeInfo title={"Trade Hash"}>
        {/* <ExternalLinkConfirmation
          id={`trade-hash`}
          target="_blank"
          className="text-ag-link"
          href={`https://${trade.account.platform}.com/trade/${trade.trade.trade_hash}`}
        > */}
        <a
          href={`https://${trade.account.platform}.com/trade/${trade.trade.trade_hash}`}
          target="_blank"
          rel={"noreferrer"}
        >
          {trade.trade.trade_hash}
        </a>

        {/* {trade.paxful_trade_hash} */}
        {/* </ExternalLinkConfirmation> */}
      </TradeInfo>

      <TradeInfo title={"Offer Hash"}>
        <a
          href={`https://${trade.account.platform}.com/offer/${trade.trade.offer_hash}`}
          target="_blank"
          rel={"noreferrer"}
        >
          {trade.trade.offer_hash}
        </a>
        {/* <ExternalLinkConfirmation
          id={`offer-hash`}
          target="_blank"
          className="text-ag-link"
          href={`https://${trade.account.platform}.com/offer/${trade.trade.offer_hash}`}
        > */}
        {/* </ExternalLinkConfirmation> */}
      </TradeInfo>

      <TradeInfo title={"Offer Type"}>
        {trade.trade.offer_type && <OfferType value={trade.trade.offer_type} />}
      </TradeInfo>

      <TradeInfo title={"Margin"}>
        <span>{trade.trade.margin}%</span>
      </TradeInfo>
      <TradeInfo title={` ${platforms[trade.account.platform].title} Fee`}>
        {formatCurrency(trade.fee, trade.trade.fiat_currency_code)}{" "}
        {trade.trade.fiat_currency_code}
        {/* {normalizeCrypto(
          trade.trade.fee_crypto_amount,
          trade.trade.crypto_currency_code
        )}{" "}
        {trade.trade.crypto_currency_code}) */}
      </TradeInfo>

      {trade.estimated_profit && (
        <TradeInfo title={"Profit"}>
          <span className="text-success">
            {formatCurrency(
              trade.estimated_profit,
              trade.trade.fiat_currency_code
            )}
          </span>
        </TradeInfo>
      )}

      <TradeInfo title={"Trade Statistics"} isLast={true}>
        <FlexBox
          mr={1}
          clasName={`d-flex align-items-center${
            isPast ? " force-primary" : ""
          }`}
        >
          <Span>
            <ATooltip
              placement={"bottom-end"}
              content={`${
                trade.chat.length - trade.attachments.length
              } Total Messages`}
            >
              <ChatIcon
                index={0}
                active={false}
                // blink={
                //   trade.unread_messages > 0 &&
                //   trade.unread_messages + trade.unread_files > 2
                // }
                blink={false}
                count={trade.chat.length - trade.attachments.length}
                disabled={!isPast}
              />
            </ATooltip>
          </Span>
          <Span
            sx={{
              marginLeft: "15px",
            }}
          >
            <ATooltip
              placement={"bottom-end"}
              content={`${trade.attachments.length} Total Attachments`}
            >
              <AttachmentIcon
                index={0}
                active={trade.has_unread_attachment}
                // blink={
                //   trade.unread_files > 0 &&
                //   trade.unread_messages + trade.unread_files > 2
                // }
                blink={false}
                count={trade.attachments.length}
                noMargin
                disabled={!isPast}
              />
            </ATooltip>
          </Span>
        </FlexBox>
      </TradeInfo>
    </Box>
  );
}

export default ShowTradeDetails;
